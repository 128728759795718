import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/github/workspace/node_modules/gatsby-theme-docz/src/base/Layout.js";
import "../style-index.css";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>Education</h1>
    <p>
  {" "}
  <span>Administrators</span> <span>Coaches</span> <span>Entrepreneurs</span> <span>Mentors</span>
    </p>
    <p>{`This modules allows you to create advance courses into your programs.`}</p>
    <h2 {...{
      "id": "view-courses"
    }}>{`View Courses`}</h2>
    <p>{`Any user with access will be able to see all the courses in the Education page.`}</p>
    <p>{`There are two different Courses view `}<strong parentName="p">{`Collections`}</strong>{` and `}<strong parentName="p">{`All Courses`}</strong></p>
    <p><strong parentName="p">{`Collections:`}</strong>{` the courses will be distributed in different carousel sections, In progress, Recently added, and Completed
`}<strong parentName="p">{`All Courses:`}</strong>{` it will show all the courses available for you in the platform.`}</p>
    <h2 {...{
      "id": "create-a-course"
    }}>{`Create a Course`}</h2>
    <p>{`Administrators and Users that Administrator choose with course creation capabilities are able to create new courses.`}</p>
    <p>{`To see how to add users to being able to create and edit courses, go to section `}<em parentName="p">{`Education Permission`}</em></p>
    <p>
  In the top right corner of the screen you will see <span>New Course</span>.
  Click on the button and it will pop-up a window where you can write the course
  name.
    </p>
    <p>
  Click in <span>Create</span> and you will go to the Course Editor,{" "}
  <span>Settings</span>.
    </p>
    <p>{`In this section you will be able to enter the following information:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Title Course:`}</strong>{` this is the name that you already chosen, but in here you can edit it.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Description:`}</strong>{` What is this course about.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Thumbnail:`}</strong>{` what best to have a cool picture on your course so it catch the eye of your audience.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Requirements:`}</strong>{` This section is divided in two:`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Required Courses:`}</strong>{` here if you already created other course you will be able to see the list of them. Those courses can be added as a pre-requisite to enter to this course. Users are not going to be able to come to this course until other courses in this section are completed.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Enable Notification:`}</strong>{` this section allows you to pop-up a notification to the user when they completed. Something cool like “ Congrats!! You are mastering it” or gamification like “You earn 30 minutes session with a mentor or coach”`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Publish:`}</strong>{` this section has two option:`}</p>
        <ol parentName="li">
          <li parentName="ol"><strong parentName="li">{`Draft:`}</strong>{` only you have access to the course.`}</li>
          <li parentName="ol"><strong parentName="li">{`Published:`}</strong>{` all users with access to the Education have access to the course.`}</li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Delete Course:`}</strong>{` of course, you know what happened here, and you won’t be able to recover this course afterwards so be careful.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      